<template>
  <div class="satisfiedDialog-container">
    <el-dialog
      v-dialogDrag
      title="满意度"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="80%"
    >
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <div class="common-screen-container">
          <div class="common-input-container">
            <span>所属店铺:</span>
            <el-select
              class="common-screen-input"
              v-model="params.shopId"
              clearable
              @change="commonFun"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in shopListDict"
                :key="item.shopId + ''"
                :label="item.shopName"
                :value="item.shopId + ''"
              >
              </el-option>
            </el-select>
          </div>
          <div class="common-input-container">
            <span>基准值:</span>
            <el-select
              class="common-screen-input"
              v-model="params.referenceType"
              @change="commonFun"
              placeholder="请选择"
              filterable
            >
              <el-option key="other" label="客户" value="other"> </el-option>
              <el-option key="system" label="系统" value="system"> </el-option>
            </el-select>
          </div>
          <!-- <div class="common-input-container">
            <el-date-picker
              v-model="params.datePicker"
              :clearable="false"
              @change="
                () => {
                  changeDate();
                }
              "
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div class="common-input-container">
            <el-radio-group v-model="params.type" @change="changeRadio">
              <el-radio-button :label="1">近7天</el-radio-button>
              <el-radio-button :label="2">近30天</el-radio-button>
              <el-radio-button :label="3">近90天</el-radio-button>
              <el-radio-button :label="0">昨天</el-radio-button>
            </el-radio-group>
          </div> -->
          <div class="common-input-container">
            <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-lately-date>
          </div>
        </div>
        <el-table
          class="common-table dialogTable"
          row-key="id"
          header-row-class-name="tableHerder"
          :data="tableData"
          @sort-change="tableSort"
        >
          <el-table-column
            v-for="(item, index) in col"
            :key="`col_${index}`"
            :fixed="
              item.prop == 'shopName' || item.prop == 'serviceName' || item.prop == 'area' || item.prop == 'subaccountName' || item.prop == 'excelDate'
                ? true
                : false
            "
            :prop="dropCol[index].prop"
            :label="item.label"
            sortable="custom"
            width="150px"
          >
            <template slot-scope="scope">
              <span
                v-if="dropCol[index].prop == 'satisfaction'"
                :class="
                  scope.row.satisfactionFlag == '0' && scope.row.satisfaction
                    ? 'warning'
                    : ''
                "
                >{{ scope.row.satisfaction || "--" }}</span
              >
              <span v-else-if="dropCol[index].prop == 'evaluateDeliveryRate'">{{
                scope.row.evaluateDeliveryRate
                  ? (scope.row.evaluateDeliveryRate * 100).toFixed(2) == "--"
                    ? "--"
                    : (scope.row.evaluateDeliveryRate * 100).toFixed(2) + "%"
                  : "--"
              }}</span>
              <span v-else>{{ scope.row[dropCol[index].prop] || "--" }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          style="text-align: center"
          :page-sizes="pagination.pageSizes"
          :page-size="params.pageSize"
          :current-page="params.page"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { satisfiedDialog, shopList } from "../../../service/dataInfo.js";
import { tableColumn, DateTransform, getLatelyDay } from "../../../utils/index.js";
import Sortable from "sortablejs";
import CommonLatelyDate from "../../../components/common/commonLatelyDate.vue"
export default {
  components: { CommonLatelyDate },
  data() {
    return {
      loading: false,
      tableColumn,
      DateTransform,
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      shopListDict: [],
      commonRadio: this.$route.query.commonRadio,
      params: {
        page: 1,
        pageSize: 10,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
      },
      tableData: [],
      centerDialogVisible: false,
      col: [
        {
          label: "日期",
          prop: "excelDate",
        },
        {
          label: "分公司(地区)",
          prop: "area",
        },
        {
          label: "客服名称",
          prop: "serviceName",
        },
        {
          label: "店铺名称",
          prop: "shopName",
        },
        {
          label: "店内子账号",
          prop: "subaccountName",
        },
        {
          label: "当日总咨询量",
          prop: "consultationNum",
        },
        {
          label: "满意度",
          prop: "satisfaction",
        },
        {
          label: "E客服评价发送率",
          prop: "evaluateDeliveryRate",
        },
        {
          label: "E客服评价很满意",
          prop: "evaluateVerySatisfaction",
        },
        {
          label: "E客服评价满意",
          prop: "evaluateSatisfaction",
        },
        {
          label: "E客服评价一般",
          prop: "evaluateCommonly",
        },
        {
          label: "E客服评价不满",
          prop: "evaluateDissatisfied",
        },
        {
          label: "E客服评价很不满",
          prop: "evaluateVeryDissatisfied",
        },
        {
          label: "客户满意度差值",
          prop: "evaluateSatisfactionDifference",
        },
      ],
      dropCol: [
        {
          label: "日期",
          prop: "excelDate",
        },
        {
          label: "分公司(地区)",
          prop: "area",
        },
        {
          label: "客服名称",
          prop: "serviceName",
        },
        {
          label: "店铺名称",
          prop: "shopName",
        },
        {
          label: "店内子账号",
          prop: "subaccountName",
        },
        {
          label: "当日总咨询量",
          prop: "consultationNum",
        },
        {
          label: "满意度",
          prop: "satisfaction",
        },
        {
          label: "E客服评价发送率",
          prop: "evaluateDeliveryRate",
        },
        {
          label: "E客服评价很满意",
          prop: "evaluateVerySatisfaction",
        },
        {
          label: "E客服评价满意",
          prop: "evaluateSatisfaction",
        },
        {
          label: "E客服评价一般",
          prop: "evaluateCommonly",
        },
        {
          label: "E客服评价不满",
          prop: "evaluateDissatisfied",
        },
        {
          label: "E客服评价很不满",
          prop: "evaluateVeryDissatisfied",
        },
        {
          label: "客户满意度差值",
          prop: "evaluateSatisfactionDifference",
        },
      ],
    };
  },
  methods: {
    commonGetDate (startTime, endTime, commonRadio) {
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.commonRadio = commonRadio
      this.satisfiedDialog()
    },
    
    //拖拽表格列
    columnDrop() {
      const wrapperTr = document.querySelector(".el-table__header-wrapper .tableHerder");
      console.log(wrapperTr);
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: (evt) => {
          const oldItem = this.dropCol[evt.oldIndex];
          console.log(evt.oldIndex);
          console.log(evt.newIndex);
          this.dropCol.splice(evt.oldIndex, 1);
          this.dropCol.splice(evt.newIndex, 0, oldItem);
        },
      });
    },
    async shopList(adminId) {
      let resData = (await shopList({ adminId, companyId: this.$route.query.companyId }))
        .data;
      this.shopListDict = resData;
    },
    getParams(params, adminId, commonRadio) {
      this.shopList(adminId);
      this.params = { ...params, page: this.params.page, pageSize: this.params.pageSize };
      this.centerDialogVisible = true;
      this.commonRadio = commonRadio;
      if (this.$refs.commonReset) {
        this.$refs.commonReset.getDate(commonRadio, params.startDate, params.endDate )
      }
      this.satisfiedDialog();
    },
    tableSort(e) {
      // 表格项排序
      let params = { ...this.params };
      params.sortField = e.prop;
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.satisfiedDialog();
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.satisfiedDialog();
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.satisfiedDialog();
    },
    changeBreadcrumb(num) {
      // 面包屑点击事件
      this.nowBreadcrumb = num;
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.satisfiedDialog();
    },
    resetBtn() {
      this.params = {
        shopId: this.$route.query.shopId,
        type: this.$route.query.type, // 筛选类型 今天/30天/90天 ...
        // datePicker: [
        //   new Date(this.$route.query.startDate),
        //   new Date(this.$route.query.endDate),
        // ], // 时间范围选择-默认当天
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        page: 1,
        pageSize: 10,
      };
      this.satisfiedDialog();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.satisfiedDialog();
    },
    async satisfiedDialog() {
      // 子账号列表
      this.loading = true;
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startDate = DateTransform(params.datePicker[0]);
        params.endDate = DateTransform(params.datePicker[1]);
      }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await satisfiedDialog({ ...params })).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
      this.columnDrop();
    },
  },
};
</script>
<style lang="less" scoped>
.warning {
  color: #f00;
}
.satisfiedDialog-container {
  text-align: left;
  .breadcrumbStyle {
    color: #00000073;
    font-weight: 500;
  }
  .breadcrumbCursor {
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
